<div class="background-image-container mt-ncs">
  <div class="container">
    <div class="jumbotron jumbotron-fluid">
      <div class="d-flex flex-wrap">
        <div>
	      <div class="logo-d-news">
    		 <img src="/assets/images/logo.png" class="ng-tns-c195-4 logo-news" alt="Логотип репозиторію"> <h2>eHSUPIR </h2>
    	      </div>
         <h3 class="display-3" style="font-size: 2.8rem; font-weight: 600;">Electronic Hryhorii <br>Skovoroda University<br>in<br>Pereiaslav Institutional Repository</h3>
         <!-- <p class="lead">DSpace is the world leading open source repository platform that enables
            organisations to:</p>
        </div>
      </div>
      <ul>
        <li>easily ingest documents, audio, video, datasets and their corresponding Dublin Core
          metadata
        </li>
        <li>open up this content to local and global audiences, thanks to the OAI-PMH interface and
          Google Scholar optimizations
        </li>
        <li>issue permanent urls and trustworthy identifiers, including optional integrations with
          handle.net and DataCite DOI
        </li>
      </ul>-->
	<h4> Ласкаво просимо до Електронного архіву Університету Григорія Сковороди в Переяславі! Електронний архів Університету Григорія Сковороди в Переяславі наповнюється наступними матеріалами: книги (монографії, підручники, навчальні посібники), наукові статті, звіти, автореферати дисертацій, дисертації, матеріали конференцій та періодичні видання УГСП у вільному доступі</h4>

<!--  <p>Join an international community of <a href="https://wiki.lyrasis.org/display/DSPACE/DSpace+Positioning" target="_blank">leading institutions using DSpace</a>.</p>
      <p>The test user accounts below have their password set to the name of this
        software in lowercase.</p>
      <ul>
        <li>Demo Site Administrator = dspacedemo+admin@gmail.com</li>
        <li>Demo Community Administrator = dspacedemo+commadmin@gmail.com</li>
        <li>Demo Collection Administrator = dspacedemo+colladmin@gmail.com</li>
        <li>Demo Submitter = dspacedemo+submit@gmail.com</li>
      </ul>-->
    </div>
  </div>
  <picture class="background-image">
    <source type="image/webp" srcset="assets/dspace/images/banner.webp 2000w, assets/dspace/images/banner.webp 1200w, assets/dspace/images/banner.webp 768w">
<!-- <source type="image/jpg" srcset="assets/dspace/images/banner.jpg 2000w, assets/dspace/images/banner.jpg 1200w, assets/dspace/images/banner.jpg 768w">
    <img alt="" [src]="'assets/dspace/images/banner.jpg'"/> without the []="''" Firefox downloads both the fallback and the resolved image -->
  </picture>
<!--  <small class="credits">Photo by <a href="https://www.pexels.com/@inspiredimages">@inspiredimages</a></small>-->
</div>

